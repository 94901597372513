:root {
  --sims-blue: #3a7dcd;
  --sims-green: #53b014;
  --sims-light-gray: #f4f4f4;
  --sims-dark-gray: #333;
}

/* Reset and base styles */
html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  color: var(--sims-dark-gray);
  font-family: Arial, sans-serif;
  background-image: url("https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/f1903d13-1bf9-4a89-949d-c91802ddb04a/ddvn7v1-cff494c5-4f67-42b5-a3e6-d81ec6f6c84a.png/v1/fill/w_1131,h_707/grid_of_ts3_plumbobs_by_titanicbobo_ddvn7v1-pre.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODAwIiwicGF0aCI6IlwvZlwvZjE5MDNkMTMtMWJmOS00YTg5LTk0OWQtYzkxODAyZGRiMDRhXC9kZHZuN3YxLWNmZjQ5NGM1LTRmNjctNDJiNS1hM2U2LWQ4MWVjNmY2Yzg0YS5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.gRUyxhRTgWjVGqRvHkp9qMrgEgT7mLCP-m5-COITIUA");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.App-header {
  background-image: linear-gradient(90deg, #3a7dcd 30%, #53b014 100%);
  color: white;
  padding: 0.7rem;
  text-align: center;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

#chapterHeaderText {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 2px solid var(--sims-blue);
  padding: 1.5rem;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TabContent {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.Tabs {
  display: flex;
  justify-content: center;
  background-color: var(--sims-dark-gray);
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
}

.Tabs button {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0.1rem 1rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  color: white;
}

.Tabs button::before {
  content: "\2605";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: var(--sims-blue);
  transition: transform 0.3s, color 0.3s;
}

.Tabs button:hover::before {
  color: var(--sims-green);
}

.Tabs button span {
  position: relative;
  z-index: 1;
}

.Tabs button.active::before {
  content: "\2605";
  color: var(--sims-green);
}

/* Cast info styles */
.castInfoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: auto;
}

.castMember {
  width: 100%;
  height: 50%;
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border: 2px solid var(--sims-blue);
}

.castMember img {
  width: 70%;
  margin-bottom: 0.1rem;
}

.castMember h3 {
  margin: 0;
}

.castMember p {
  margin: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  justify-content: flex-end;
  background-color: var(--sims-light-gray);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1.7rem;
  border: 2px solid var(--sims-blue);
  width: 90%;
}

.contentContainer p {
  margin: 0.7rem;
  padding: 0.5rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  font-family: Arial, sans-serif;
}

li {
  list-style: none;
  margin: 0.7rem;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  font-family: Arial, sans-serif;
}

/* Image Wrapper */
.imageWrapper {
  display: flex;
  align-items: center;
  z-index: 1000;
  position: relative;
  justify-content: center;
}

.imageWrapper img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0.1rem 0;
  cursor: pointer;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
}

.imageContainer img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0.1rem 0;
  cursor: pointer;
  border-radius: 4px;
}

/* Arrow Icon Button */
.imageWrapper button {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

/* Arrow Icon */
.arrowButton {
  color: var(--sims-blue);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

/* Disable pointer events for disabled button */
.arrowButton[disabled] {
  color: var(--sims-light-gray);
}

/* Adjust padding and margin */
.imageWrapper .arrowButton {
  padding: 0;
  margin: 0;
}

.entry {
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  flex-direction: column;
  padding: 1.2rem;
  background-color: var(--sims-light-gray);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--sims-blue);
  margin: 1rem auto;
}

.entryStory {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines you want to display when collapsed */
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--sims-blue);
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  font-family: Arial, sans-serif;
  padding: 0 1rem;
  line-height: 1.3;
  height: auto; /* Adjust based on your design */
  transition: all 0.3s ease;
  min-width: 100%;
  max-width: 100%;
  text-align: center;
  min-height: 100%;
}

.expanded {
  display: block;
  -webkit-line-clamp: none;
  height: auto;
}

.entry h2 {
  margin: 0;
  text-align: center;
}

.entryContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.imageContainer img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.imageButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonPlaceholder {
  width: 96px; /* Example width, adjust based on your actual button size */
  height: 36px; /* Example height, adjust based on your actual button size */
  background-color: transparent; /* Ensures the placeholder is invisible */
  border: none; /* Removes any border */
  display: inline-block; /* Ensures it takes up space like a button */
}

.entriesContainer {
  display: flex;
  flex-direction: column;
}

.chapterNavigation {
  display: flex;
  justify-content: space-between; /* Aligns children to opposite ends */
  align-items: center;
  width: 100%;
  padding: 10px;
}

.previousButton,
.nextButton {
  background-color: var(--sims-blue);
  color: #fff;
  border: none;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s;
}

.previousButton:hover,
.nextButton:hover {
  background-color: var(--sims-green);
}

.closeButton {
  width: 1.3rem;
  height: 1.3rem;
  background-color: var(--sims-blue);
  color: #fff;
  border: none;
  padding: 0;
  border-radius: 4px; /* Or adjust the border-radius to make it rounded */
  cursor: pointer;
  font-family: "Arial", sans-serif;
  transition: background-color 0.3s;
  position: absolute;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  z-index: 1000;
}

.modalOverlay .closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modalContent {
  background-color: #fff;
  border: 2px solid var(--sims-blue);
  padding: 1.5rem;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 50%;
}

/* Modal Title */
.modalContent h3 {
  color: var(--sims-blue);
  font-family: "Arial", sans-serif;
  text-align: center;
}

/* Modal Image */
.modalContent img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal Description */
.modalContent p {
  color: #000;
  font-family: "Arial", sans-serif;
}

/* Close Button */
.modalContent button {
  background-color: var(--sims-blue);
  color: #fff;
  border: none;
  padding: 0.3rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modalContent button:hover {
  background-color: var(--sims-green);
}

.modalButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.button {
  background-color: var(--sims-blue);
  color: #fff;
  border: none;
  padding: 0.1rem 0.2rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: var(--sims-green);
}

.contentContainer h2 {
  margin: 0;
  text-align: center;
}

p {
  line-height: 1.2;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
}

h3 {
  font-size: 1.2rem;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
  font-family: Arial, sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  text-align: justify;
}

ul li {
  margin: 1.5rem;
}

img {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.photoCaption {
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.2rem;
}

.chapterHeading {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  margin: 1rem;
  text-align: center;
  color: var(--sims-light-gray);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.chapterTitle {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 2px solid var(--sims-blue);
  padding: 1.5rem;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  font-weight: bold;
}

#previousButton,
#nextButton {
  z-index: 1000;
  background-color: var(--sims-blue);
  color: #fff;
  border: none;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  transition: background-color 0.3s;
}

.entriesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
}

.chapterNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#previousButton:hover,
#nextButton:hover {
  background-color: var(--sims-green);
}

.entry img {
  max-width: 90%;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.entry h2 {
  margin: 0;
  text-align: center;
}

.chapterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--sims-light-gray);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--sims-blue);
  width: 100%;
}

.disabled {
  pointer-events: none;
  background-color: var(--sims-light-gray);
  color: var(--sims-dark-gray);
}

@media (min-width: 768px) {
  img {
    max-width: 80%;
  }
  .castMember {
    width: 40%;
  }
  .Tabs button {
    font-size: 1.2rem;
    margin: 0 3rem;
  }
  .entry {
    width: 80%;
  }
  .modalContent img {
    max-width: 75%;
  }
  .modalContent {
    height: 70%;
    width: 70%;
  }
  .modalContent button {
    padding: 0.5rem;
  }
  .closeButton {
    width: 2rem;
    height: 2rem;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    padding: 1rem;
  }
  .TabContent {
    width: 95%;
  }
  .buttonPlaceholder {
    width: 110px; /* Adjust based on your actual larger button size */
    height: 40px; /* Adjust based on your actual larger button size */
  }
}
